<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">

                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目分类">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="baobiao = true" type="primary2" size="mini" round icon="el-icon-share">报表</el-button>
                                    <!-- <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button> -->
                                </span>
                                <h3 class="tit">枢纽站场列表</h3>
                            </div>
                            <div class="box-content newbox">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ total }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>“十四五”投资（亿元）<small>（亿元）</small></h5>
                                            <b class="date-num">{{ total3 }}</b>
                                        </div>
                                    </div>
                                </div>
                                <el-table @sort-change="changeSort" @row-dblclick="openDetails3" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <el-table-column prop="" label="操作" :width="80" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetails3(scope.row)"><i class="el-icon-info icons"></i>详情</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="XMMC" label="项目名称" show-overflow-tooltip :width="200" sortable>
                                        <template slot-scope="scope">
                                            {{scope.row.XMMC||'无'}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="JSNR" label="建设内容" show-overflow-tooltip sortable></el-table-column>
                                    <el-table-column prop="SSWTZ" label="十四五总投资（亿元）" show-overflow-tooltip :width="190" sortable></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30,50,100,400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>

        <el-dialog :title="dialogTableVisibleData.XMMC||dialogTableVisibleData.ZHKYSNMC||dialogTableVisibleData.KYZMC" :visible.sync="dialogTableVisibleMap" class="mapdialog mapdialog1">
            <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="550px">
            <Hz :totalXmlx="totalXmlx" :totalXmxz="totalXmxz" :totalXzqh="totalXzqh" />
        </el-drawer>
        <div v-if="dialogTableVisible3">
            <SNZC :data="dialogDetails" :round="dialogRound" :isedits="false" @updatemessage="updatemessages('dialogTableVisible3')" />
        </div>
        <el-drawer title="报表" :visible.sync="baobiao" :direction="'rtl'" size="100%">
            <BbHinge v-if="baobiao" />
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Hinge/Hz.vue"; //汇总
import { exportData } from "../../js/util/export.js"; //导出表格
import Map from "../Map/Index.vue";
import SNZC from "../Query/SNZC.vue";
import BbHinge from "../Query/BbHinges.vue";

export default {
    name: "Home",
    components: {
        Hz,
        Map,
        SNZC,
        BbHinge,
    },
    data() {
        return {
            sortData: {
                field: "XH",
                sort: "asc",
            },
            baobiao: false,
            dialogRound: Math.round(Math.random() * 500),
            dialogTableVisibleMap: false,
            dialogTableVisibleData: {},
            dialogTableVisible3: false,
            dialogDetails: {},
            activecollapse: "1",
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索

            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedXmxz: [], //项目性质
            checkedXmlx: [], //项目类型
            checkedJsxz: [],
            region: [""], //已选中行政区划
            /**查询条件 end*/

            xmxzShow: false, //项目性质查询条件显隐
            xmlxShow: false, //项目类型查询条件显隐
            checkList: [],
            checkAllXmxz: false,
            checkAllXmlx: false,
            checkAllJsxz: false,
            // xmxzList: ["续建项目", "新开工项目"],
            jsxzList: [
                { value: "续建项目", label: "续建项目", disabled: false },
                { value: "新开工项目", label: "新开工项目", disabled: false },
                { value: "谋划项目", label: "谋划项目", disabled: false },
            ],
            xmlxList: [
                {
                    value: "客运枢纽",
                    label: "客运枢纽",
                    disabled: false,
                },
                { value: "货运枢纽", label: "货运枢纽", disabled: false },
                {
                    value: "一般客运站",
                    label: "一般客运站",
                    disabled: false,
                },
                {
                    value: "多式联运型货运枢纽",
                    label: "多式联运型货运枢纽",
                    disabled: false,
                },
                {
                    value: "通用集散型货运枢纽",
                    label: "通用集散型货运枢纽",
                    disabled: false,
                },
                {
                    value: "物流园区集疏运",
                    label: "物流园区集疏运",
                    disabled: false,
                },
                {
                    value: "县（区）货运站场",
                    label: "县（区）货运站场",
                    disabled: false,
                },
                {
                    value: "县市客运站",
                    label: "县市客运站",
                    disabled: false,
                },
                {
                    value: "乡镇综合服务站",
                    label: "乡镇综合服务站",
                    disabled: false,
                },
            ],
            currentPage: 0, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            direction: "rtl",
            props: { multiple: true },
            tableData: [], //请求列表
            total: 0, //请求记录数
            total1: 0, //
            total2: 0, //
            total3: 0, //
            sswtz: 0,
            totalXmxz: [], //汇总按项目性质
            totalXmlx: [], //汇总按项目类型
            totalXzqh: [], //汇总按行政区划
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 265;
        }, 100);
        this.search();
        // this.checkxmlx();
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
            } else {
                this.sortData = {
                    field: "XMXH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        updatemessages(e) {
            this.search();
        },
        // checkxmlx(e) {
        //     this.checkedXmlx = [];
        //     if (e == "乡镇运输服务站") {
        //         this.xmlxList.forEach((r) => {
        //             r.disabled = true;
        //         });
        //     }
        //     if (e == "货运枢纽") {
        //         let arr = ["县级客运站", "综合客运枢纽"];
        //         this.xmlxList.forEach((r) => {
        //             r.disabled = arr.indexOf(r.value) < 0 ? false : true;
        //         });
        //     }
        //     if (e == "客运枢纽") {
        //         let arr = [
        //             "多式联运型货运枢纽（物流园区）",
        //             "集散通用型货运枢纽（物流园区）",
        //             "县（区）货运站场",
        //         ];
        //         this.xmlxList.forEach((r) => {
        //             r.disabled = arr.indexOf(r.value) < 0 ? false : true;
        //         });
        //     }
        // },

        openDetails3(row) {
            this.dialogRound = Math.round(Math.random() * 500);
            this.dialogDetails = row;
            this.dialogTableVisible3 = true;
        },
        exportData() {
            exportData("Plan_hinge", this.postData());
        },

        //查询
        search() {
            this.handleChanges(1);
        },
        //汇总
        // getData(){

        // },
        postData() {
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };

            var query_fl = {
                Name: "XMLX",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };

            var query_jsxz = {
                Name: "XMXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };

            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify([query_fl, query_xmmc, query_jsxz]),
            };
            return postData;
        },
        clearCondition() {
            this.xmmc = "";
            this.checkedJsxz = [];
            this.checkedXmlx = [];
        },
        handleChanges(type) {
            if (!type) {
                this.checkedXmlx = [];
                this.currentPage = 1;
            }
            this.http
                .post(
                    "/api/Plan_hinge/GetPageData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                    }
                });
            this.http
                .post(
                    "/api/Plan_hinge/getPageTotal",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    this.total1 = res.xmsl;
                    this.total3 = res.sswtz;
                });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>

<style lang="less">
.newbox {
    .el-table__body-wrapper {
        height: calc(100% - 45px) !important;
    }
}
.mapdialog {
    .el-dialog {
        width: 80%;
        overflow: hidden;
    }
}
.mapdialog1 {
    height: 100%;
    overflow: hidden;
    .el-dialog {
        height: 80%;
    }
}
</style>